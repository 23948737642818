<template>
    <section id="denverFineFurniture">
        <div class="vanity">
            <picture>            
                <source media="(orientation: portrait) and (min-width:600px)" srcset="../assets/stock/avif/md/drawerCoffeeTable1000.avif 1000w">
                <source media="(orientation: portrait)" srcset="../assets/stock/avif/sm/drawerCoffeeTable600.avif 600w">

                <source media="(orientation: landscape) and (min-width: 1000px)" srcset="../assets/stock/avif/xl/liveVanity.avif 900w">
                <source media="(orientation: landscape) and (min-width: 600px)" srcset="../assets/stock/avif/lg/liveVanity.avif 700w">
                <source media="(orientation: landscape)" srcset="../assets/stock/avif/lg/liveVanity.avif 700w">
                
                <img class="dresserPic" alt="Custom dresser" src="../assets/stock/resize/checkerDresser.jpg" loading="lazy"/>
            </picture>
        </div>
        <div class="dresser">
            <picture>            
                <source media="(orientation: portrait) and (min-width:600px)" srcset="../assets/stock/avif/md/walnutCherryConsole1000.avif 1000w">
                <source media="(orientation: portrait)" srcset="../assets/stock/avif/sm/walnutCherryConsole600.avif 600w">

                <source media="(orientation: landscape) and (min-width: 1000px)" srcset="../assets/stock/avif/md/drawerCoffeeTable1000.avif 1000w">
                <source media="(orientation: landscape) and (min-width: 600px)" srcset="../assets/stock/avif/sm/drawerCoffeeTable600.avif 600w">
                <source media="(orientation: landscape)" srcset="../assets/stock/avif/sm/drawerCoffeeTable600.avif 600w">
                
                <img class="vanityPic" alt="custom live edge vanity" src="../assets/stock/resize/liveVanity.jpg" loading="lazy"/>
            </picture>
        </div>
    </section>
</template>



<style>
#denverFineFurniture {
    display: block;
    height: 26vw;
    width: 100%;
    background-color: #bebebe;
    position: relative;
    border-top: 8px solid white !important;
    border-bottom: 8px solid white !important;
    min-height: 302px;
}
#denverFineFurniture div.vanity {
    position: relative;
    left: 5%;
    top: -38%;
    width: 80vw;
    height: auto;
    z-index: 3;
    transition-delay: 1ms;
}
#denverFineFurniture .hidden {
    transform: translateX(0);
    transform: translateY(100%);    
}
#denverFineFurniture .show {
    transform: translateX(0);
    transform: translateY(0);

}
#denverFineFurniture img.vanityPic {
    width: 100%;
    height: 100%;
}
#denverFineFurniture div.vanity::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 8px;
    left: -12px;
    background: #72939c;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
}
#denverFineFurniture picture {
    position: relative;
}
#denverFineFurniture div.dresser {
    position: relative;
    left: 15%;
    top: -44%;
    width: 80vw;
    height: auto;
    z-index: 2;
    transition-delay: 1ms;
}
#denverFineFurniture img.dresserPic {
    width: 100%;
    height: 100%;
}
#denverFineFurniture div.dresser::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 8px;
    left: -12px;
    background: #ab6531;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
}


@media(min-width: 500px) {
    #denverFineFurniture div.vanity {
        width: 70vw;
    }
    #denverFineFurniture div.dresser {
        width: 70vw;
        left: 23%;
    }
}
@media(min-width: 650px) {
    #denverFineFurniture div.vanity {
        width: 60vw;
    }
    #denverFineFurniture div.dresser {
        width: 60vw;
        left: 37%;
    }
}
@media(min-width: 750px) {
    #denverFineFurniture div.vanity {
        width: 63vw;
    }
    #denverFineFurniture div.dresser {
        width: 71vw;
        left: 23%;
        top: -62%;
    }
}
@media(min-width: 850px) {
    #denverFineFurniture div.vanity {
        top: -47%;
    }
    #denverFineFurniture div.dresser {
        top: -92%;
    }
    #denverFineFurniture {
        height: 40vw;
    }
}
@media(min-width: 1000px) {    
    #denverFineFurniture div.vanity {
        max-width: 730px;
    }
    #denverFineFurniture div.dresser {
        max-width: 730px;
        width: 61vw;
        left: 35%;
    }
}
@media (min-width: 1200px) { 
    #denverFineFurniture div.vanity {
        top: -21%;
    }
    #denverFineFurniture div.dresser {
        max-width: 730px;
        width: 60vw;
        left: 39%;
        top: -64%;
    }
    #denverFineFurniture {
        max-height: 500px;
    }
}
@media (min-width: 1500px) {
    #denverFineFurniture div.dresser {
        left: 52vw;
    }
}
@media (min-width: 1700px) {
    #denverFineFurniture div.vanity {
        max-width: 820px;
    }
    #denverFineFurniture div.dresser {
        left: 52%;
        max-width: 820px;
        top: -96%;
    }
}
</style>