
<template>
    <div class="paraWrap pw2">   
        <div class="srvWrap">
            <div class="srvItem">
                <h2 class="hidden">Your Friendly<br>Neighborhood Woodshop</h2>
                <!-- <div class="hidden underline"></div> -->
                <p class="hidden">A small shop situated in northwest Denver, we offer our services for homes and businesses in the Denver area and throughout the Front Range. </p>
            </div>
        </div>
        <scroll-parallax :up="true" :speed=".25">
            <lax2pic/>
        </scroll-parallax>
    </div>
</template>

<script>
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import lax2pic from '@/components/Lax2pic.vue';

export default {
  components: {
    ScrollParallax,
    lax2pic
  },
  setup(){
    return {
        publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped>
.paraWrap {
    height: 750px;
    overflow: hidden;
    position: relative;
}
#Services {
    display: inline-block;
    height: auto;
    width: 100%;
    background-color: grey;
}
.pw2 .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 0;
}
.pw2 div, .pw2 div picture {
    width: 100%;
    height: 100%;
}
.srvWrap {
    z-index: 1;
    position: absolute;
    right: 5vw;
    top: 42%;
}
.srvWrap .hidden {
    transform: translateX(0);
    transform: translateY(100%);
}
.srvWrap .show {
    transform: translateX(0);
    transform: translateY(0);

}
.srvWrap .underline {
    display: block;
    border-bottom: 2px solid white;
    height: 1px;
    width: 287px;
    box-shadow: 2px 2px 9px black;
    margin-top: 5px;
    transition-delay: 400ms;
}
.srvWrap p {
    transition-delay: 400ms;
}
.srvItem {
    margin-top: 63px;
    text-align: right;
}
.srvItem h2 {
    font-size: 30px;
    text-shadow: 2px 2px 9px black;
    padding: 10px 0;
}

.srvItem p {
    font-size: 16px;
    text-shadow: 2px 2px 9px black;
    text-align: right;
    background: rgba(0, 0, 0, .5);
    padding: 10px 20px;
    border-radius: 8px;
    display: inline-block;
    max-width: 75vw;
    width: 383px;
}
@media (min-width: 400px) {
    .srvItem h2 {
        font-size: 34px;
    }
    .srvItem p {
        font-size: 16px
    }
}
@media (min-width: 768px) {
    .srvItem {
        margin-top: 0;
    }
    .srvItem h2 {
        font-size: 40px;
    }
    .srvItem p {
        font-size: 18px
    }
    .srvWrap {
        right: 10vw;
    }
}
</style>