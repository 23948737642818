
<template>
    <div class="paraWrap pw3">
        <div class="srvWrap">
            <div class="srvItem">
                <h2 class="hidden">100% Custom</h2>
                <!-- <div class="hidden underline"></div> -->
                <p class="hidden">Every piece is created to your requirements, specific to your needs. If you aren't thrilled with the work, our job isn't done.</p>
            </div>
        </div>
        <scroll-parallax :left="true" :speed=".2" direction="x" class="pictureWrap">
            <lax3pic/>
        </scroll-parallax>
    </div>
</template>

<script>
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import lax3pic from '@/components/Lax3pic.vue';

export default {
  components: {
    ScrollParallax,
    lax3pic
  }
}
</script>

<style scoped>
.paraWrap {
    height: 800px;
    overflow: hidden;
    position: relative;
}
.pictureWrap {
    height: 100%;
    width: 100%;
}
.srvWrap {
    z-index: 1;
    position: absolute;
    left: 7vw;
    top: -4%;
}
.srvWrap .hidden {
    transform: translateX(0);
    transform: translateY(100%);
}
.srvWrap .show {
    transform: translateX(0);
    transform: translateY(0);

}
.srvWrap .underline {
    display: block;
    border-bottom: 2px solid white;
    height: 1px;
    width: 287px;
    box-shadow: 2px 2px 9px black;
    margin-top: 5px;
    transition-delay: 400ms;
}
.srvWrap p {
    transition-delay: 400ms;
}
.srvItem {
    margin-top: 63px;
    text-align: right;
}
.srvItem h2 {
    font-size: 34px;
    text-shadow: 2px 2px 9px black;
    padding: 10px 0;
    text-align: left;
}

.srvItem p {
    font-size: 16px;
    text-shadow: 2px 2px 9px black;
    text-align: left;
    background: rgba(0, 0, 0, .5);
    padding: 10px 20px;
    border-radius: 8px;
    max-width: 75vw;
}
@media (min-width: 380px) {
    .srvWrap {
        top: 4%;
    }
}
@media (min-width: 429px) {
    .srvWrap {
        top: 8%;
    }
}
@media (min-width: 520px) {
    .srvWrap {
        top: 46%;
    }
}
@media (min-width: 768px) {
    .srvItem h2 {
        font-size: 40px;
    }
    .srvItem p {
        font-size: 18px;
        width: 433px;
    }
    .srvWrap {
        left: 10vw;
    }
}
</style>