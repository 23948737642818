<template>
    <section id="about">
        <div class="aboutBG">
            <div class="container">
                <div class="row">
                    <div class="aboutWrap">
                        <div class="aboutItem">
                            <h3>Mission</h3>
                            <p>We're committed to high-quality woodworking and design &ndash; craftsmanship and beauty come first. We're happy to offer a wide range of services for residential, commercial, and industy projects.</p>
                        </div>
                        <div class="aboutItem">
                            <h3>100% Custom</h3>
                            <p>Every piece that leaves the lab is is created with the customer in mind, exactly to their requirements, specific to their needs. We offer 3D modeling so you can see the finished product before a singe board is cut.</p>
                        </div>
                        <div class="aboutItem">
                            <h3>Locally Made</h3>
                            <p>Headquartered in North Denver, any business you decide to give us creates a butterfly effect of local support, since all materials and supplies are sourced from other businesses throughout area.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
#about {
    width: 100%;
    background-color: white;
}
#about h3, #about p {
    color: black;
}
.aboutBG {
    padding: 50px 0;
}

.container {
    padding: 0 40px;
    margin: 0 auto;
    width: calc(100% - 80px);
}

.aboutWrap {
    justify-content: center;
    display: flex;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    margin: 0 -50px -25px;
}

.aboutItem {
    width: 33.33333%;
    padding: 0 85px;
    margin-bottom: 25px;
}

#about h3 {
    margin-bottom: 18px;
    font-size: 22px;
    line-height: 1.3;
    color: #545c5f;
}

#about p {
    margin-bottom: 0;
    font-size: 17px;
    color: #545c5f;
    line-height: 1.5;
}
</style>