
<template>
    <section id="customWoodFurniture">
        <div class="blueConsole">
            <picture>            
                <source media="(orientation: portrait) and (min-width:600px)" srcset="../assets/stock/avif/md/blueConsole900.avif 900w">
                <source media="(orientation: portrait)" srcset="../assets/stock/avif/sm/blueConsole600.avif 600w">

                <source media="(orientation: landscape) and (min-width: 1200px)" srcset="../assets/stock/avif/lg/blueConsole1300.avif 1300w">
                <source media="(orientation: landscape) and (min-width: 1000px)" srcset="../assets/stock/avif/md/blueConsole900.avif 900w">
                <source media="(orientation: landscape) and (min-width: 600px)" srcset="../assets/stock/avif/sm/blueConsole600.avif 600w">
                <source media="(orientation: landscape)" srcset="../assets/stock/avif/sm/blueConsole600.avif 600w">
                
                <img class="blueConsolePic" alt="Custom wooden console painted blue" src="../assets/stock/resize/blueConsole.jpg" loading="lazy"/>
            </picture>
        </div>
        <div class="dovetail">
            <picture>            
                <source media="(orientation: portrait) and (min-width:600px)" srcset="../assets/stock/avif/md/doevtailBox900.avif 900w">
                <source media="(orientation: portrait)" srcset="../assets/stock/avif/sm/doevtailBox600.avif 600w">

                <source media="(orientation: landscape) and (min-width: 1000px)" srcset="../assets/stock/avif/lg/doevtailBox1300.avif 1300w">
                <source media="(orientation: landscape) and (min-width: 600px)" srcset="../assets/stock/avif/md/doevtailBox900.avif 900w">
                <source media="(orientation: landscape)" srcset="../assets/stock/avif/sm/doevtailBox600.avif 600w">
                
                <img class="dovetailPic" alt="Custom Wooden Box with dovetail joinery" src="../assets/stock/resize/doevtailBox.jpg" loading="lazy"/>
            </picture>
        </div>
    </section>
</template>


<style>



#customWoodFurniture {
    display: block;
    height: 26vw;
    width: 100%;
    background-color: #bebebe;
    position: relative;
    border-top: 8px solid white;
    border-bottom: 8px solid white;
    min-height: 302px;
}
#customWoodFurniture div.blueConsole {
    position: relative;
    left: 5%;
    top: -38%;
    width: 80vw;
    height: auto;
    z-index: 2;
    transition-delay: 1ms;
}
#customWoodFurniture .hidden {
    transform: translateX(0);
    transform: translateY(100%);    
}
#customWoodFurniture .show {
    transform: translateX(0);
    transform: translateY(0);

}
#customWoodFurniture img.blueConsolePic {
    width: 100%;
    height: 100%;
}
#customWoodFurniture div.blueConsole::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 8px;
    left: -12px;
    background: #ab6531;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
}
#customWoodFurniture picture {
    position: relative;
}
#customWoodFurniture div.dovetail {
    position: relative;
    left: 15%;
    top: -44%;
    width: 80vw;
    height: auto;
    z-index: 3;
    transition-delay: 750ms;
}
#customWoodFurniture img.dovetailPic {
    width: 100%;
    height: 100%;
}
#customWoodFurniture div.dovetail::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 8px;
    left: -12px;
    background: #72939c;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
}

@media(min-width: 450px) {
    #customWoodFurniture div.blueConsole {
        width: 69vw;
    }
}
@media(min-width: 500px) {
    #customWoodFurniture div.blueConsole {
        width: 70vw;
    }
    #customWoodFurniture div.dovetail {
        width: 70vw;
        left: 23%;
        top: -64%;
    }
}
@media(min-width: 575px) {
    #customWoodFurniture div.blueConsole {
        width: 60vw;
        top: -54%;
    }
    #customWoodFurniture div.dovetail {
        width: 60vw;
        left: 37%;
        top: -75%;
    }
}
@media(min-width: 750px) {
    #customWoodFurniture div.blueConsole {
        width: 63vw;
    }
    #customWoodFurniture div.dovetail {
        width: 61vw;
        left: 33%;
        top: -95%;
    }
}
@media(min-width: 850px) {
    #customWoodFurniture div.blueConsole {
        top: -47%;
    }
    #customWoodFurniture div.dovetail {
        top: -92%;
    }
    #customWoodFurniture {
        height: 40vw;
    }
}
@media(min-width: 1000px) {    
    #customWoodFurniture div.blueConsole {
        width: 53vw;
    }
    #customWoodFurniture div.dovetail {
        width: 50vw;
        left: 44%;
    }
}
@media (min-width: 1100px) { 
    #customWoodFurniture div.blueConsole {
        top: -35%;
    }
    #customWoodFurniture div.dovetail {
        width: 51vw;
        left: 45%;
        top: -77%;
    }
    #customWoodFurniture {
        max-height: 500px;
    }
}
@media (min-width: 1250px) {
    #customWoodFurniture div.blueConsole {
        top: -27%;
        width: 46vw;
    }
    #customWoodFurniture div.dovetail {
        width: 48vw;
        left: 47%;
        top: -93%;
    }
}
@media (min-width: 1350px) {
    #customWoodFurniture div.dovetail {
        top: -102%;
    }
}
@media (min-width: 1500px) {
    #customWoodFurniture div.blueConsole {
        top: -36%;
        width: 43vw;
    }
    #customWoodFurniture div.dovetail {
        left: 47vw;
        top: -110%;
    }
}
@media (min-width: 1700px) {
    #customWoodFurniture div.blueConsole {
        top: -33%;
        width: 40%;
    }
    #customWoodFurniture div.dovetail {
        left: 53%;
        width: 41%;
        top: -128%;
    }
}
</style>