
<template>
    <div class="paraWrap pw4">
        <div class="srvWrap">
            <div class="srvItem">
                <h2 class="hidden">Locally Made</h2>
                <!-- <div class="hidden underline"></div> -->
                <!-- <p class="hidden hidden1">We're committed to high&dash;quality woodworking and design &ndash; craftsmanship and beauty come first</p>
                <p class="hidden hidden2">Whether it&rsquo;s residential or commercial, big or small, if you have a project, we&rsquo;ll tackle it</p> -->

                <p class="hidden hidden1">All materials and supplies are sourced from businesses in the Denver area. You'll get personal service and direct accountability.</p>
            </div>
        </div>
        <scroll-parallax class="scrollWrap" :up="true" :speed=".25">
            <lax4pic/>
        </scroll-parallax>
    </div>
</template>

<script>
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import lax4pic from '@/components/Lax4pic.vue';

export default {
  components: {
    ScrollParallax,
    lax4pic
  }
}
</script>

<style scoped>
.paraWrap {
    height: 1068px;
    overflow: hidden;
    position: relative;
}
.scrollWrap {
    width: 100%;
    height: 100%;
}
.srvWrap {
    z-index: 1;
    position: absolute;
    right: 5vw;
    bottom: 27%;
    display: inline-block;
}
.srvWrap .hidden {
    transform: translateX(0);
    transform: translateY(100%);
}
.srvWrap .show {
    transform: translateX(0);
    transform: translateY(0);

}
.srvWrap .hidden.hidden1 {
    transition-delay: 500ms;
    margin-bottom: 20px;

}
.srvWrap .hidden.hidden2 {
    transition-delay: 1500ms;

}
.srvWrap .underline {
    display: block;
    border-bottom: 2px solid white;
    height: 1px;
    width: 287px;
    box-shadow: 2px 2px 9px black;
    margin-top: 5px;
    transition-delay: 400ms;
}
.srvItem {
    margin-top: 63px;
    text-align: right;
}
.srvItem h2 {
    font-size: 34px;
    text-shadow: 2px 2px 9px black;
    padding: 10px 0;
}

.srvItem p {
    font-size: 16px;
    text-shadow: 2px 2px 9px black;
    text-align: right;
    background: rgba(0, 0, 0, .5);
    padding: 10px 20px;
    border-radius: 8px;
    display: inline-block;
    max-width: 75vw;
}

@media (min-width: 400px) {
    .paraWrap {
        bottom: 21%;
    }
}

@media (min-width: 768px) {
    .paraWrap {
        height: 1200px;
    }
    .srvItem h2 {
        font-size: 40px;
    }
    .srvItem p {
        font-size: 18px;
        width: 466px;
    }
    .srvWrap {
        right: 10vw;
    }
}
</style>