<template>
  <div class="vidContainer preMount" v-if="!isMounted"></div>
  <div class="vidContainer" v-else>
    <videoBackground
      v-if="isMounted"
      src="/routerMov1080p.mp4"
      poster="/movRouterPoster.avif"
      :sources="[
        {src: '/routerMov720p.mp4', res: 900, autoplay: true},
        {src: '/routerMov576p.mp4', res: 638, autoplay: true}
      ]"
    ></videoBackground>
  </div>
  <!-- <video playsinline autoplay muted loop poster="../assets/movRouterPoster.png" id="bgvid" src="../assets/video/routerMov720p.mp4">

  </video> -->
</template>

<script>
import videoBackground from 'vue-responsive-video-background-player';
import { onMounted, ref } from 'vue'
// import bigVid from '../assets/video/routerMov1080px.mp4'
// import midVid from '../assets/video/routerMov720px.mp4'
// import smlVid from '../assets/video/routerMov576px.mp4'

const isMounted = ref(false)

export default {
  setup() {
    onMounted(() => {
      isMounted.value = true
    })
    return { isMounted }
  },
  components: {
    videoBackground
  }
}
</script>
<style>
.vue-responsive-videobg {
  position: initial !important;
}
video {
    display: none !important;
    visibility: hidden !important;
    -o-object-position: center bottom;
    object-position: center bottom;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100vw;
    height: 100%;
    position: initial;
    margin-left: auto;
    top: 0 !important;
    left: 0 !important;
    transform: initial !important;
}
.fixedBannerMovWrap {
  position: absolute;
  top: -29%;
  height: 121%;
  width: 100%;
}
.fixedBannerMovWrap div {
  position: relative;
  height: 120%;
  width: 100%;
  top: 3%;
}
#customFurnitureBanner {
    padding: 0;
    position: relative;
    height: 98vh;
    max-height: 920px;
    height: 98svh;
}
.vidContainer {
  position: static !important;
}
.preMount {
  background-image: url("/public/movRouterPoster.avif");
}
@media (min-width: 380px) {
  video {
    display: block !important;
    visibility: visible !important;
  }
}
</style>