<template>
  <form id="contactForm" @submit.prevent="handleSubmit">
    <label for="first">First Name</label>
    <input type="text" required name="first" v-model="first">
    <label for="last">Last Name</label>
    <input type="text" name="last" v-model="last">
    <label for="email">Email</label>
    <input type="email" required name="email" v-model="email">
    <label for="phone">Phone</label>
    <input type="phone" name="phone" v-model="phone">
    <label for="project" required name="project">Tell us briefly what you're looking for</label>
    <input type="text" name="project" v-model="project">
    <p>Contact Preference</p><br>
    
    <label for="phone" class="radio">Phone</label>
    <input type="radio" id="phonePref" name="pref" value="phone" class="radioInput" v-model="phonePref">
    <label for="email" class="radio">Email</label>
    <input type="radio" id="emailPref" name="pref" value="email" class="radioInput" v-model="emailPref">
    <p class="submit-button">
          <button type="submit">Submit</button>
        </p>
  </form>
</template>

<script>
import { projectFirestore } from '@/firebase/config';
export default {
data() {
  return {
    first: '',
    last: '',
    email: '',
    phone: '',
    project: '',
    phonePref: '',
    emailPref: ''
  }
},
methods: {
  handleSubmit() {
    let clientMessage = {
      firstName: this.first,
      lastName: this.last,
      email: this.email,
      phone: this.phone,
      project: this.project,
      phonePref: this.phonePref,
      emailPref: this.emailPref
    }

    projectFirestore.collection('clientMessage').add(clientMessage)
  }
}
}
</script>

<style scoped>
form {
    max-width: 420px;
    margin: 30px auto;
    background: white;
    text-align: left;
    padding: 40px;
    border-radius: 10px;
    margin-top: 100px;
}
label, p {
  color: #aaa;
    display: inline-block;
    margin: 43px 0 1px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
input {
  display: block;
    padding: 0px 6px 10px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
}
input.radioInput {
  display: inline-block;
  width: auto;
  margin-right: 10px;
  padding-top: 10px;
  position: relative;
  top: 2px;
}
label.radio {
  padding: 0 10px 10px 0;
  margin-right: 4px;
  margin-top: 18px
}
.navbar {
  background-color: #545c5f;
  height: 55px;
}
img.navLogo {
  transform: scale(1);
}
</style>