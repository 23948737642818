<template>
    <section id="Services">
        <div class="srvBG">
            <div class="container">
                <div class="row">
                    <div class="srvWrap">
                        <div class="srvItem hidden">
                            <h3>Furnishings</h3>
                            <p>Anything from tables and shelves, to </p>
                        </div>
                        <div class="srvItem hidden">
                            <h3>CNC Work</h3>
                            <p>Custom signage and decorations.</p>
                        </div>
                        <div class="srvItem hidden">
                            <h3>Restoration</h3>
                            <p>Specializing in doors and tables</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
#Services {
    display: inline-block;
    height: auto;
    width: 100%;
    background-color: grey;
}
.srvWrap:nth-child(2) {
    transition-delay: 200ms;
}
.srvWrap:nth-child(3) {
    transition-delay: 400ms;
}
</style>