<template>
    <section>
      <footer>
        <div class="footerWrap">
          <div class="footerRow">
            <div class="footerCol left">
              <div class="footerThing">
                <img class="footerLogo" alt="Denver Lumber Laboratory - Custom Furniture and Restoration" src="../assets/DLLoffwhiteYellowSawH2.svg" loading="lazy" /></div>
            </div>
            <div class="footerCol divider">
              <div class="dividerThing"></div>
            </div>
            <div class="footerCol right">
              <div class="footerThing thing1"><a href='mailto:steve@denverlumberlaboratory.com'><img alt="Email Us Icon" src="../assets/stock/icons/email-svgrepo-com.svg"  loading="lazy"/>steve@denverlumberlaboratory.com</a></div>
              <div class="footerThing thing2"><a href='tel:573-303-1427'><img alt="Phone Us icon" src="../assets/stock/icons/phone-svgrepo-com.svg"  loading="lazy"/>573-303-1427</a></div>
            </div>
          </div>
        </div>
      </footer>
    </section>
</template>

<style scoped>
footer {
    height: auto;
    background: #545454;
    border-top: 8px solid white;
}
.footerRow {
    display: inline-block;
}
.footerCol.left {
    width: 100%;
}
.footerCol.divider {
    display: inline-block;
    width: 100%;
}
.dividerThing {
    border-top: 1px solid #fbf5e8;
    display: block;
    width: 109px;
    height: 1px;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
}
img.footerLogo {
    justify-self: center;
    width: 60vw;
    /* filter: brightness(0) saturate(100%) invert(100%) sepia(42%) saturate(711%) hue-rotate(297deg) brightness(101%) contrast(102%) drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); */
    display: flex;
    max-width: 345px;
    margin: -28px auto -16vw;
    height: 60vw;
    min-height: 54px;
    min-width: 201px;
    max-height: 345px;
}
.footerCol.left {
    float: left;
}
.footerCol.right {
    width: auto;
    display: inline-block;
    margin: 15px auto 21px;
}
.footerCol.right .footerThing {
    color: white;
    /* text-shadow: 3px 3px 8px #555; */
    font-size: 20px;
    text-align: left;
    margin-bottom: 6px;
}
.thing1 img {
    /* filter: brightness(0) saturate(100%) invert(100%) sepia(42%) saturate(711%) hue-rotate(297deg) brightness(101%) contrast(102%) drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); */
    height: 29px;
    width: 29px;
    position: relative;
    right: 9px;
    top: 9px;
}
.thing2 img {
    /* filter: brightness(0) saturate(100%) invert(100%) sepia(42%) saturate(711%) hue-rotate(297deg) brightness(101%) contrast(102%) drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); */
    height: 29px;
    width: 29px;
    position: relative;
    right: 9px;
    top: 9px;
}
@media (min-width: 455px) {
    img.footerLogo {
      width: 273px;
      height: 273px;
      margin: -28px auto -75px;
  }
}
@media (min-width: 768px) {
  .footerRow {
    display: flex;
    justify-content: space-between;
    height: 213px;
    overflow: hidden;
  }
  .footerCol.left {
      width: 67%;
  }
  .footerCol.divider {
    display: flex;
    width: auto;
  }  
  .dividerThing {
      border-top: none;
      display: block;
      width: 1px;
      margin-top: 35px;
      margin-left: auto;
      margin-right: auto;
      border-left: 1px solid #fbf5e8;
      margin-top: 76px;
      height: 64px;
  }
  .footerCol.right {
      width: 100%;
      margin-top: 69px;
  }
  img.footerLogo {
    margin: -28px auto -98px;    
    padding: 0 54px;
  }
  .footerCol.right .footerThing {
    margin: 0 auto 6px;
    width: 326px;
    text-align: left;
  }
}
@media (min-width: 1500px) {
  .footerCol.right .footerThing.thing1 {
    margin-bottom: 14px;
  }
  .footerCol.right {
    margin-top: 86px;
  }
  .footerCol.right .footerThing.thing1 {
    margin-bottom: 14px;
    display: inline-block;
    width: 50%;
    max-width: 365px;
  }
  .footerCol.right .footerThing.thing2 {
    margin-bottom: 14px;
    display: inline-block;
    width: 21%;
  }
}
</style>