<template>
        
        <picture>            
            <source media="(orientation: portrait) and (min-width: 1300px)" srcset="../assets/stock/avif/xl/barsBed.avif 2000w">
            <source media="(orientation: portrait) and (min-width: 900px)" srcset="../assets/stock/avif/xl/barsBed.avif 1300w">
            <source media="(orientation: portrait) and (min-width: 500px)" srcset="../assets/stock/avif/xl/barsBed.avif 1000w">
            <source media="(orientation: portrait)" srcset="../assets/stock/avif/xl/barsBed.avif 600w">

            <source media="(orientation: landscape) and (min-width: 1300px)" srcset="../assets/stock/avif/xl/bathroomShelf.avif 2000w">
            <source media="(orientation: landscape) and (min-width: 1000px)" srcset="../assets/stock/avif/lg/bathroomShelf.avif 1300w">
            <source media="(orientation: landscape) and (min-width: 900px)" srcset="../assets/stock/avif/md/bathroomShelf.avif 1000w">
            <source media="(orientation: landscape)" srcset="../assets/stock/avif/lg/bathroomShelf.avif 600w">
            
            <img loading="lazy" alt="custom made bathroom shelves, drawers and counter" src="../assets/stock/resize/bathroomShelf.jpg"/>
        </picture>
</template>

<style lang="scss" scoped>
img, source {
        height: 101%;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        position: relative;
        bottom: 245vw;
        object-position: center;
}
@media (min-width: 380px) {
        img, source {
                bottom: 245vw;
                height: 108%;
        }
}
@media (min-width: 410px) {
        img, source {
                bottom: 211vw;
        }
}
@media (min-width: 435px) {
        img, source {
                height: 110%;
                bottom: 200vw;
        }
}
@media (min-width: 500px) {        
        img, source {
                bottom: 173vw;
                height: 115%;
        }
}
@media (min-width: 600px) {        
        img, source {
                bottom: 150vw;
        }
}
@media (min-width: 690px) {        
        img, source {
                bottom: 135vw;
        }
}
@media (min-width: 788px) {
        img, source {
                bottom: 120vw;
        }
}
@media (min-width: 920px) {
        img, source {
                bottom: 105vw;
        }
}
@media (min-width: 1072px) {
        img, source {
                height: 106%;
                bottom: 90vw;
        }
}
@media (min-width: 1200px) {        
        img, source {
                bottom: 83vw;
        }
}
@media (min-width: 1400px) {
        img, source {
                bottom: 73vw;
        }
}
@media (min-width: 1520px) {
        img, source {
                bottom: 66vw;
        }
}
@media (min-width: 1670px) {
        img, source {
                bottom: 60vw;
        }
}
@media (min-width: 1844px) {
        img, source {
                bottom: 54vw;
        }
}
@media (min-width: 2000px) {
        img, source {
                bottom: 89%;
        }
}
</style>