
<template>
    <div class="paraWrap pw1"> 
        <div class="floatWrap">
            <div class="floatItem">
                <h2 class="hidden">Fine Woodworking</h2>
                <p class="hidden hidden1">We're proud to produce tables, shelves, media consoles, bookcases, vanities, CNC inlays and engraving, wall paneling, mantles and just about everything in between. We're committed to high&dash;quality woodworking and design &ndash; craftsmanship and beauty come first.</p>
            </div>
        </div>
        <lax1pic/>
    </div>
</template>

<script>
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import lax1pic from '@/components/Lax1pic.vue';

export default {
  components: {
    ScrollParallax,
    lax1pic
  }
}
</script>

<style scoped>
.paraWrap {
    height: 600px;
    overflow: hidden;
    border-bottom: 8px solid white !important;
    position: relative;
    border-top: 8px solid white !important;
}
.floatWrap {
    left: 7vw;
    bottom: 8%;
    position: absolute;
    z-index: 2;
}
.floatWrap .hidden {
    transform: translateX(0);
    transform: translateY(100%);
}
.floatWrap .show {
    transform: translateX(0);
    transform: translateY(0);

}
.floatWrap .underline {
    display: block;
    border-bottom: 2px solid white;
    height: 1px;
    width: 287px;
    box-shadow: 2px 2px 9px black;
    margin-top: 5px;
    transition-delay: 400ms;
}
.floatWrap p.hidden1 {
    width: 65vw;
    transition-delay: 400ms;
    position: relative;
    left: 0;
    top: 0vh;
    max-width: 75vw;
    min-width: 268px;
}
.floatWrap p.hidden2 {
    width: 85vw;
    transition-delay: 400ms;
    position: relative;
    top: 1vh;
    left: 0;
    max-width: 281px;
}
.floatItem {
    text-align: left;
    position: relative;
}
.floatWrap p {
    font-size: 16px;
}
.floatItem h2 {
    font-size: 34px;
    padding: 10px 20px 10px 0;
text-shadow: 2px 2px 9px black;
display: inline-block;
border-radius: 5px;
}

.floatItem p {
    font-size: 16px;
text-shadow: 1px 1px 2px black;
padding: 10px 20px;
background: rgba(0, 0, 0, .5);
border-radius: 8px;
}
@media (min-width: 768px) {    
    .paraWrap {
        height: 700px;
    }
    .floatWrap {
        z-index: 1;
        position: absolute;
        left: 10vw;
        top: 53%;
    }
    .floatItem h2 {
        font-size: 40px;
        padding: 10px 0;
    }
    .floatItem p {
        font-size: 18px;
    }
    .floatWrap p.hidden1 {
        width: 40vw;
        transition-delay: 400ms;
        position: relative;
        max-width: 350px;
    }
    .floatWrap p.hidden2 {
        width: 70vw;
        transition-delay: 1200ms;
        position: relative;
        max-width: 365px;
        margin-top: 5px;
    }
}
@media (min-width: 1000px) {
    .paraWrap {
        height: 630px;
    }
    .floatWrap p {
        font-size: 18px;                                                                                                                                                                                                                                                    
    }
}
@media (min-width: 1200px) {
    
    .floatWrap p.hidden1 {
        width: 81vw;
        transition-delay: 400ms;
        position: relative;
        max-width: 591px;
    }
}
</style>