<template>
        <picture>            
            <source media="(orientation: portrait) and (min-width:500px)" srcset="../assets/stock/avif/xl/liveVanity.avif 1000w">
            <source media="(orientation: portrait)" srcset="../assets/stock/avif/portrait/liveVanity600.avif 600w">

            <source media="(orientation: landscape) and (max-width: 600px)" srcset="../assets/stock/avif/portrait/liveVanity1000.avif 2x">
            <source media="(orientation: landscape) and (max-width: 1300px)" srcset="../assets/stock/avif/lg/walnutCherryConsoleShort1300.avif">
            <source media="(orientation: landscape)" srcset="../assets/stock/avif/xl/walnutCherryConsoleShort.avif">
            
            <img alt="custom live edge sink and vanity" src="../assets/stock/avif/portrait/liveVanity1000.avif"/>
        </picture>
</template>

<style lang="scss" scoped>
img, source {
    height: 100%;
    position: relative;
    width: 100%;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
}
@media (min-width: 1000px) {
    img, source {
        object-position: right;
    }
}
</style>