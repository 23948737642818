
<template>
    <div class="paraWrap pw5">   
        <!-- <picture class="inlayMtnPic">            
            <source media="(orientation: portrait)" srcset="../assets/stock/avif/xl/inlayMtn.avif 600w">

            <source media="(orientation: landscape)" srcset="../assets/stock/avif/xl/inlayMtn.avif 700w">
            
            <img alt="Custom Mountain Inlay in floor" src="../assets/stock/resize/inlayMtn.jpg" loading="lazy"/>
        </picture> -->
        <div class="srvWrap">
            <div class="srvItem">
                <h2 class="hidden">Free Quote</h2>
                <!-- <div class="hidden underline"></div> -->
                <p class="hidden">We offer free 3D renderings of the finished product before a single board is cut. Give us a hollar if you're thinking of starting your next project! Let's make something amazing together!</p>
            </div>
        </div>
        <!-- <scroll-parallax class="mtnInlayWrap" :up="true" :speed=".25">
            <lax5pic/>
        </scroll-parallax> -->
    </div>
</template>

<script>
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import lax5pic from '@/components/Lax5pic.vue';

export default {
  components: {
    ScrollParallax,
    lax5pic
  },
  setup(){
    return {
        publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped>
.mtnInlayWrap {
    width: 100%;
    height: 100%;
}
.inlayMtnPic img {
    height: 100%;
    width: auto;
    position: relative;
    left: -50%;
    margin-left: 25%;
}
.inlayMtnPic source {
    height: 100%;
    width: auto;
    position: relative;
    left: -50%;
    margin-left: 25%;
}
#Services {
    display: inline-block;
    height: auto;
    width: 100%;
    background-color: grey;
}
.pw5 .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 0;
}
.pw5 div, .pw5 div picture {
    width: 100%;
    height: 100%;
}
.srvWrap {
    z-index: 1;
    position: absolute;
    left: 5vw;
    top: 30%;
}
.srvWrap .hidden {
    transform: translateX(0);
    transform: translateY(100%);
}
.srvWrap .show {
    transform: translateX(0);
    transform: translateY(0);

}
.srvWrap .underline {
    display: block;
    border-bottom: 2px solid white;
    height: 1px;
    width: 287px;
    box-shadow: 2px 2px 9px black;
    margin-top: 5px;
    transition-delay: 400ms;
}
.srvWrap p {
    transition-delay: 800ms;
}
.srvItem {
    margin-top: 63px;
    text-align: left;
}
.srvItem h2 {
    font-size: 34px;
    text-shadow: 2px 2px 9px black;
    padding: 10px 0;
}

.srvItem p {
    font-size: 16px;
    text-shadow: 2px 2px 9px black;
    text-align: left;
    background: rgba(0, 0, 0, .5);
    padding: 10px 20px;
    border-radius: 8px;
    display: inline-block;
    max-width: 75vw;
    width: 400px;
}
.paraWrap {
    height: 386px;
    overflow: hidden;
    position: relative;
    background-image: url("../assets/stock/avif/xl/inlayMtn.avif");
    background-size: cover;
    background-position: center;
}
@media (min-width: 460px) {
    .paraWrap {
        height: 400px;
    }
}
@media (min-width: 580px) {
    .srvWrap {
        top: 34%;
    }
}
@media (min-width: 670px) {
    .paraWrap {
        height: 500px;
    }
    .srvWrap {
        top: 48%;
    }
}
@media (min-width: 768px) {
    .srvItem h2 {
        font-size: 40px;
    }
    .srvItem p {
        font-size: 18px;
        width: 453px;
    }
    .srvWrap {
        left: 10vw;
        top: 40%;
    }
}
</style>