<template>
  <Navbar />
  <div class="content">
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  components: { Navbar }
}
</script>

<style lang="scss">
@font-face {
    font-family: "Haymaker";
    src: local("Haymaker"), url('./assets/fonts/Haymaker.ttf') format("truetype");
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  border-color: white !important;
  overflow-x: hidden;
  border-style: solid;
  border-width: 8px 8px 8px;
  position: relative;
  z-index: 0;
  max-width: 2000px;
  margin: 0 auto;
}

nav {
  padding: 30px;

  a {
    color: var(--primary);
    text-decoration: none;
    

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
