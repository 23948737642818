<template>
  <section id="customFurnitureBanner">
    <h1 class="bannerBG">
      <div class="bannerLogo">
        <img class="logoImg notScrolled" id="logoImg" alt="Denver Lumber Laboratory - Custom Furniture and Decor" src="../assets/DLLoffwhiteYellowSawH2.svg" />
      </div>
    </h1>   
    <div class="fixedBannerMovWrap">
      <ScrollParallax :up="true" :speed=".4">
        <BannerMov/>
      </ScrollParallax> 
    </div>
  </section>
  <!-- <About /> -->
  <Parallax1 />
  <!-- <Services /> -->
  <Parallax2 />
  <Commercial />
  <Parallax3 />
  <Restoration />
  <Parallax4 />
  <Dual3 />
  <Parallax5 />
  <Footer />
</template>

<script>
import Residential from '@/components/Services.vue'
import Commercial from '@/components/Commercial.vue'
import Restoration from '@/components/Restoration.vue'
import Services from '@/components/Services.vue'
import About from '@/components/About.vue'
import Parallax1 from '@/components/Parallax1.vue'
import Parallax2 from '@/components/Parallax2.vue'
import Parallax3 from '@/components/Parallax3.vue'
import Parallax4 from '@/components/Parallax4.vue'
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import Dual3 from '@/components/Dual3.vue'
import BannerMov from '@/components/BannerMov.vue'
import Parallax5 from '@/components/Parallax5.vue'
import Footer from '@/components/Footer.vue'
import { onMounted } from 'vue'

export default {
  name: "HomeView",
  components: { Residential, Commercial, Services, About, Parallax1, ScrollParallax, BannerMov, Parallax2, Parallax3, Parallax4, Restoration, Dual3, Parallax5, Footer },
  setup() {
    window.addEventListener('scroll', function() {
      let scroll = window.scrollY;
      
      var getNav = document.querySelectorAll('.navbar');
      for(var i = 0; i < getNav.length; i++) {
        if(scroll < 300) {
          getNav[i].classList.add('reg');
          getNav[i].classList.remove('shrink');
        } else {
          getNav[i].classList.add('shrink');
          getNav[i].classList.remove('reg');
        }
      }

      var thisLogo = document.querySelectorAll('.logoImg');
      for(var i = 0; i < thisLogo.length; i++) {
        if (scroll > 10) {
          thisLogo[i].classList.remove('notScrolled');
          thisLogo[i].classList.add('scrolled');

          var translate = 1 - (scroll * .0016);
          thisLogo[i].style.transform = 'scale('+ translate +')';

        } else {
          thisLogo[i].classList.remove('scrolled');    
          thisLogo[i].classList.add('notScrolled');
        }
      }

      var navLogo = document.querySelectorAll('.navLogo');
      var mainLogo = document.querySelector('#logoImg');
      var elDistanceToTop = window.pageYOffset + mainLogo.getBoundingClientRect().top
      
      for(var i = 0; i < navLogo.length; i++) {
        if (scroll > elDistanceToTop ) {
          navLogo[i].style.transform = 'scale(1)';
        } else {      
          navLogo[i].style.transform = 'scale(0)';
        }
      }

      var mov = document.querySelectorAll('video');
      for(var i = 0; i < mov.length; i++) {
        if (scroll > 900 ) {
          mov[i].style.display = 'none';
        } else {      
          mov[i].style.display = 'block';
        }
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show')
          } else {
            entry.target.classList.remove('show')
          }
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden');
      hiddenElements.forEach((el) => observer.observe(el))

    })
    
    onMounted(() => {
      window.scrollTo(0,0);
    })
  }
}

// window.onbeforeunload = function () {
//     window.scrollTo(0,1);

    
// };
</script>

<style>
h1.bannerBG {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 4;
}
.hidden {
  opacity: 0;
  transition: all 1s;
  filter: blue(5px);
  transform: translateX(-100%)
}
.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}
@media(prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}
.paraWrap {
  display: block;
}
.bannerLogo {
  position: relative;
  z-index: 1;
}
.logoImg {
  filter: brightness(0) saturate(100%) invert(100%) sepia(42%) saturate(711%)
    hue-rotate(297deg) brightness(101%) contrast(102%) drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  width: 80vw;
  margin: 0 auto;
  max-width: 450px;
  max-height: 450px;
  transform: translateY(0) scale(1);
  height: 80vw;
}
.bannerBG {
  caret-color: transparent;
}
section.prlxWrap {
    height: 700px;
    overflow: hidden;
}
.prlx1 {
  transform: translate3d(0) scale(1.3);
  will-change: transform;
  height: 140%;
    width: auto;
    position: relative;
    left: -50%;
}
.prlx2 {
  z-index: -2;
}
.prlx3 {
  z-index: -1;
}
</style>
