<template>    
        <!-- <img 
        class="default" 
        src="../assets/stock/resize/angledBed.jpg"  
        alt="custom made wooden wall accent"
        srcset="../assets/stock/avif/portrait/angledBed700.avif 480w, 
                ../assets/stock/avif/md/angledBed.avif 800w,
                ../assets/stock/avif/lg/angledBed.avif 1300w, 
                ../assets/stock/avif/xl/angledBedSFW.avif 2000w"
        sizes="(max-width: 480px) 480px, 
               (max-width: 800px) 800px, 
               (max-width: 1300px) 1300px, 
               (min-width: 1301px) 2000px"
        >  -->
        
        <picture>            
            <source media="(orientation: portrait) and (min-width:500px)" srcset="../assets/stock/avif/lg/angledBed.avif 1300w">
            <source media="(orientation: portrait)" srcset="../assets/stock/avif/portrait/angledBed700.avif 600w">

            <source media="(orientation: landscape) and (min-width: 1300px)" srcset="../assets/stock/avif/lg/angledBed.avif 2000w">
            <source media="(orientation: landscape) and (min-width: 1000px)" srcset="../assets/stock/avif/lg/angledBed.avif 1300w">
            <source media="(orientation: landscape) and (min-width: 600px)" srcset="../assets/stock/avif/md/angledBed.avif 900w">
            <source media="(orientation: landscape)" srcset="../assets/stock/avif/md/angledBed.avif 800w">
            
            <img loading="lazy" alt="custom wooden wall panels behind bed" src="../assets/stock/large/angledBed.jpeg"/>
        </picture>
</template>

<style lang="scss" scoped>
img, source {
        height: 120%;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        position: relative;
        bottom: 93vw;
        object-position: center;
}
@media (min-width: 410px) {
        img, source {
                bottom: 85vw;
        }
}
@media (min-width: 435px) {
        img, source {
                bottom: 81vw;
        }
}
@media (min-width: 500px) {        
        img, source {
                height: 120%;
                bottom: 71vw;
        }
}
@media (min-width: 580px) {        
        img, source {
                bottom: 61vw;
        }
}
@media (min-width: 660px) {        
        img, source {
                bottom: 49vw;
        }
}
@media (min-width: 878px) {        
        img, source {
                bottom: 43vw;
        }
}
@media (min-width: 1000px) {        
        img, source {
                bottom: 35vw;
        }
}
@media (min-width: 1200px) {
        img, source {
                bottom: 30vw;
        }
}
@media (min-width: 1384px) {
        img, source {
                bottom: 25vw;
        }
}
@media (min-width: 1656px) {
        img, source {
                bottom: 22vw;
        }
}
@media (min-width: 1820px) {
        img, source {
                bottom: 20vw;
        }
}
@media (min-width: 2000px) {
        img, source {
                bottom: 51%;
        }
}
</style>