<template>
    <div class="navbar reg">
        <nav>
            <div class="linksLeft">                
                <!-- <div class="hamIcon">
                        <img alt="Menu icon" src="../assets/stock/icons/hamburger-white.svg" />
                </div> -->
                <div class="desktop">
                    <!-- <a href="#residential">Residential</a>
                    <a href="#commercial">Commercial</a> -->
                </div>
            </div>
            <a class="navLogoWrap" href="http://denverlumberlaboratory.com">
                <img class="navLogo" alt="Denver Lumber Laboratory - Custom Furniture and Restoration" src="../assets/DLLWebNavLogoWhite.svg" />
            </a>
            <div class="linksRight">
                <div class="contactIcon">
                    <router-link :to="{name: 'contact'}">
                        <img alt="Contact Us icon" src="../assets/stock/icons/hi-white-100.svg" />
                    </router-link>
                </div>
                <div class="desktop" v-if="user">
                    <span>Hi there, {{ user.displayName }}</span>
                    <button @click="handleSubmit">Logout</button>
                </div>
                <div class="desktop" v-else>
                    <router-link class="btn" :to="{ name: 'contact'}">Contact</router-link>
                    <!-- <router-link class="btn" :to="{ name: 'signup' }" v-if="!user">Signup</router-link>
                    <router-link class="btn" :to="{ name: 'login' }" v-if="!user">Login</router-link> -->
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import useLogout from '../composables/useLogout'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'

export default {
    setup() {
        const { user } = getUser() 

        const { logout, error } = useLogout()
        
        const router = useRouter()

        const handleSubmit = async () => {
            await logout()
            if (!error.value) {
                console.log('user logged out')
                router.push({ name: 'login'})
            } else {
                console.log(error)
            }
        }
        const contact = () => {

        }
        return { useLogout, handleSubmit, error, router, user }
    }
}
</script>

<style>
.navbar {
    padding: 10px 0 10px 0;
    height: 75px;
    background-color: transparent;
    transition: 
        height .25s ease-in-out,
        color .25s ease-in-out, 
        background .25s ease-in-out, 
        transform .25s ease-in-out;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;    
    border-width: 8px 8px 0 8px;
    border-color: white !important;
    border-style: solid;
    z-index: 10;
    max-width: 2000px;
    margin: 0 auto;
}
.navbar.reg {
    visibility: hidden;
}
.shrink {
    padding: 10px 0 10px 0;
    background-color: black;
    position: fixed;
    height: 55px;
    border-width: 8px 8px 0 8px;
    border-color: white !important;
    border-style: solid;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
}
nav {
    height: 100%;
    transition: all 0.4s ease-in-out;
    width: calc(100% - 80px);
    display: flex;
    margin: 0rem auto;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
}
img.navLogo {
    justify-self: center;
    width: 200px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(42%) saturate(711%) hue-rotate(297deg) brightness(101%) contrast(102%) drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    display: flex;
    transform: scale(0);
    transition: transform .25s ease-in-out;
    max-width: 200px;
    padding: 10px;
    height: 61px;
}
a.navLogoWrap {
    display: inherit;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
}
.linksLeft {
    float: left;
    display: flex;
    align-items: flex-start;
    margin-left: -20px;
}
.linksRight {
    float: right;
    display: none;
    /* display: flex; */
    align-items: flex-start;
    margin-right: -20px;
}
.linksLeft div, .linksRight div {
    display: flex;
}
.linksLeft a, .linksRight a {
    margin: 0 9px 0 9px;
    padding: 10px 7px;
    white-space: nowrap;
    font-size: 16px;
    position: relative;
}
.linksRight a {
    padding: 10px 0;
    margin: 0;
}
.linksLeft a::after, .linksRight a::after  {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.50s ease-out;
}

.linksLeft a:hover::after, .linksRight a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.linksLeft div.desktop, .linksRight div.desktop {
    display: none;
}
.contactIcon {
    display: block;
}
.contactIcon img {
    height: 36px;
}
.hamIcon {
    display: flex;
}
.hamIcon img {
    height: 36px;
}
@media (min-width: 768px){
    .linksRight div.desktop {
        display: flex;
    }
    .linksRight div.contactIcon {
        display: none;
    }
    .linksLeft div.hamIcon {
        display: none;
    }
    .linksLeft div.desktop {
        display: flex;
    }
    .linksRight a {
        padding: 10px 7px;
    }
} 
</style>