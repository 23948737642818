<template>    
        <picture>            
            <source media="(orientation: portrait) and (min-width: 900px)" srcset="../assets/stock/avif/lg/slabTable1300.avif 1300w">
            <source media="(orientation: portrait) and (min-width: 500px)" srcset="../assets/stock/avif/md/slabTable1000.avif 1000w">
            <source media="(orientation: portrait)" srcset="../assets/stock/avif/md/slabTable1000.avif 1000w">

            <source media="(orientation: landscape) and (min-width: 1300px)" srcset="../assets/stock/avif/xl/slabTable.avif 2000w">
            <source media="(orientation: landscape) and (min-width: 1000px)" srcset="../assets/stock/avif/lg/slabTable1300.avif 1300w">
            <source media="(orientation: landscape) and (min-width: 900px)" srcset="../assets/stock/avif/md/slabTable1000.avif 1000w">
            <source media="(orientation: landscape)" srcset="../assets/stock/avif/xl/slabTable.avif 2000w">
            
            <img loading="lazy" alt="custom made slab table" src="../assets/stock/resize/slabTable.jpg"/>
        </picture>
</template>

<style lang="scss" scoped>
img {
    height: 100%;
    position: relative;
    overflow: hidden;
    width: 246%;
    -o-object-fit: cover;
    object-fit: cover;
    left: 40vw;
}
// picture {
//     height: 100%;
//     width: 100%;
// }
@media(min-width: 500px) {    
    img { 
        left: 1%;
    }
}
@media(min-width: 650px) {    
    img { 
        left: 1%;
    }
}
@media(min-width: 768px) {
    img { 
        left: 24%;
    }
}
@media (min-width: 950px) {
    img {
        left: 30%;
    }
}
@media (min-width: 1100px) {
    img {
        left: 28%;
        width: 140%;
        object-fit: cover;
    }
}
@media (min-width: 1400px) {
    img {
        left: 22%;
    }
}
@media (min-width: 1650px) {
    img {
        left: 14%;
    }
}
</style>