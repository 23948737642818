import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Signup from '../views/auth/Signup.vue'
import login from '../views/auth/login.vue'
import ContactForm from '../views/ContactForm.vue'



import { projectAuth } from '@/firebase/config'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactForm
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
