<template>        
    <picture>            
        <source media="(orientation: portrait) and (min-width:500px)" srcset="../assets/stock/avif/lg/inlayMtn1300.avif 1300w">
        <source media="(orientation: portrait)" srcset="../assets/stock/avif/sm/inlayMtn600.avif 600w">

        <source media="(orientation: landscape) and (min-width: 1300px)" srcset="../assets/stock/avif/xl/inlayMtn.avif 2000w">
        <source media="(orientation: landscape) and (min-width: 1000px)" srcset="../assets/stock/avif/lg/inlayMtn1300.avif 1300w">
        <source media="(orientation: landscape) and (min-width: 600px)" srcset="../assets/stock/avif/md/inlayMtn1000.avif 900w">
        <source media="(orientation: landscape)" srcset="../assets/stock/avif/sm/inlayMtn600.avif 800w">
        
        <img loading="lazy" alt="custom wooden mountain inlay" src="../assets/stock/resize/inlayMtn.jpg"/>
    </picture>
</template>

<style lang="scss" scoped>

// img, source {
//     height: 120%;
//     -o-object-fit: cover;
//     object-fit: cover;
//     width: 100%;
//     position: relative;
//     bottom: 82vw;
//     object-position: center;
// }
// @media (min-width: 410px) {
//     img, source {
//             bottom: 78vw;
//     }
// }
// @media (min-width: 435px) {
//     img, source {
//             bottom: 69vw;
//     }
// }
// @media (min-width: 500px) {        
//     img, source {
//             height: 120%;
//             bottom: 42%;
//     }
// }
// @media (min-width: 600px) {        
//     img, source {
//             bottom: 42%;
//     }
// }
// @media (min-width: 770px) {        
//     img, source {
//             bottom: 45%;
//     }
// }
// @media (min-width: 1200px) {        
//     img, source {
//             bottom: 53%;
//     }
// }
// @media (min-width: 1400px) {
//     img, source {
//             bottom: 50%;
//     }
// }
</style>