import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/performance'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAWDWYbStV2zzd4Uxv8k1-u58xzqotmPkQ",
    authDomain: "denver-lumber-laboratory.firebaseapp.com",
    projectId: "denver-lumber-laboratory",
    storageBucket: "denver-lumber-laboratory.appspot.com",
    messagingSenderId: "456595126946",
    appId: "1:456595126946:web:76f437e3ae3a961fbeb5fb",
    measurementId: "G-KHNTLVP2WY"
  };

//inite firebase
firebase.initializeApp(firebaseConfig)

//init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const perf = firebase.performance()

//timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, perf, timestamp }